/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 scene.gltf
Author: DavidFalke (https://sketchfab.com/davidfalke)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/binocular-ww2-wehrmacht-for-sale-a06f78bed3ef45068fe53eef00f90084
Title: Binocular - WW2 - Wehrmacht - For Sale
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { Mesh } from 'three';
import { useFrame } from '@react-three/fiber';

export function Model(props: any) {
  const { nodes, materials } = useGLTF('/models/binocular/scene.gltf') as any;

  const meshRef = useRef<Mesh>(null!);
  useFrame(() => {
    // Изменяем поворот объекта с течением времени
    meshRef.current.rotation.y -= 0.001;
  });

  return (
    <group {...props} dispose={null} position={[0, 0, 0]} ref={meshRef}>
      <group rotation={[0, 0, 0]} scale={1}>
        <group rotation={[0, 0, 0]}>
          <mesh
            geometry={nodes.barrel_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.connector_right_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.body_right_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.body_left_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.connector_left_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.screws_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.stripholder_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.Lense_right_low_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
          <mesh
            geometry={nodes.lenseCasing_low3_MA_Zeiss6x30_0.geometry}
            material={materials.MA_Zeiss6x30}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/scene.gltf');
