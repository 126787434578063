/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 scene.gltf
Author: Franck.Demongin (https://sketchfab.com/Franck.Demongin)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/joystick-44b10bf52d4d4b7484960d89fa70ed92
Title: Joystick
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { Mesh } from 'three';
import { useFrame } from '@react-three/fiber';

export const JoystickModel = (props: any) => {
  const meshRef = useRef<Mesh>(null!);
  const { nodes, materials } = useGLTF('/models/joystick/scene.gltf') as any;

  useFrame(() => {
    // Изменяем поворот объекта с течением времени
    meshRef.current.rotation.y -= 0.001;
  });

  return (
    <group {...props} dispose={null} ref={meshRef}>
      <group rotation={[-Math.PI / 2, 0, 0]} position={[0, -3, 0]} scale={50}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, 0.054, 0]}>
            <mesh
              geometry={nodes.Object_7.geometry}
              material={materials.Plastique_base}
            />
            <mesh
              geometry={nodes.Object_8.geometry}
              material={materials.Plastique_base}
            />
            <mesh
              geometry={nodes.Object_9.geometry}
              material={materials.Plastique_base}
            />
          </group>
          <mesh
            geometry={nodes.Object_5.geometry}
            material={materials.Bande}
            position={[0, 0.054, 0]}
          />
          <mesh
            geometry={nodes.Object_11.geometry}
            material={materials.Caoutchouc}
            position={[0, 0.054, 0]}
          />
          <mesh
            geometry={nodes.Object_13.geometry}
            material={materials.Plastique_lisse}
            position={[0, 0.055, 0]}
            rotation={[0, 0.001, 0]}
          />
          <mesh
            geometry={nodes.Object_15.geometry}
            material={materials['Plastique_btn-2']}
            position={[0.015, 0.253, -0.014]}
          />
          <mesh
            geometry={nodes.Object_17.geometry}
            material={materials['Plastique_btn-1']}
            position={[0, 0.261, -0.021]}
          />
          <mesh
            geometry={nodes.Object_19.geometry}
            material={materials.Plastique_lisse}
            position={[0, 0.055, 0]}
          />
          <mesh
            geometry={nodes.Object_21.geometry}
            material={materials.Plastique_lisse}
            position={[0, 0.054, 0]}
          />
          <mesh
            geometry={nodes.Object_23.geometry}
            material={materials['Plastique_lisse.001']}
            position={[0, 0.054, 0]}
          />
          <mesh
            geometry={nodes.Object_25.geometry}
            material={materials.Btn_white}
            position={[0, 0.054, 0]}
          />
          <mesh
            geometry={nodes.Object_27.geometry}
            material={materials.material}
            position={[0, 0.054, 0]}
            rotation={[0, -0.088, 0]}
          />
          <mesh
            geometry={nodes.Object_29.geometry}
            material={materials.Caoutchouc}
            position={[-0.018, 0.258, -0.017]}
          />
          <mesh
            geometry={nodes.Object_31.geometry}
            material={materials.material}
            position={[0, 0.052, 0]}
          />
          <mesh
            geometry={nodes.Object_33.geometry}
            material={materials.Acier}
            position={[0, 0.053, 0]}
          />
          <mesh
            geometry={nodes.Object_35.geometry}
            material={materials.Diode}
            position={[0, 0.054, 0]}
          />
          <mesh
            geometry={nodes.Object_37.geometry}
            material={materials['Plastique_lisse.001']}
            position={[0, 0.01, -0.126]}
            rotation={[-Math.PI / 2, 0, 0]}
          />
          <mesh
            geometry={nodes.Object_39.geometry}
            material={materials['Plastique_lisse.001']}
            position={[0, 0.054, 0.001]}
          />
          <mesh
            geometry={nodes.Object_41.geometry}
            material={materials['Plastique_base.001']}
            position={[0, 0.05, 0]}
          />
          <mesh
            geometry={nodes.Object_43.geometry}
            material={materials.Acier}
            position={[0, 0.06, 0]}
          />
          <mesh
            geometry={nodes.Object_45.geometry}
            material={materials.Gachette}
            position={[0.001, 0.226, -0.031]}
          />
          <mesh
            geometry={nodes.Object_47.geometry}
            material={materials.Caoutchouc}
            position={[0.003, 0.169, 0.014]}
          />
          <mesh
            geometry={nodes.Object_49.geometry}
            material={materials.Plastique_lisse}
            position={[0.002, 0.25, -0.014]}
          />
          <mesh
            geometry={nodes.Object_51.geometry}
            material={materials.Acier}
            position={[0.01, 0.078, -0.015]}
          />
          <mesh
            geometry={nodes.Object_53.geometry}
            material={materials.Acier}
            position={[0.025, 0.192, -0.004]}
          />
          <mesh
            geometry={nodes.Object_55.geometry}
            material={materials.Acier}
            position={[0.018, 0.21, -0.012]}
          />
          <mesh
            geometry={nodes.Object_57.geometry}
            material={materials.Caoutchouc}
            position={[0.087, 0.003, 0.139]}
          />
          <mesh
            geometry={nodes.Object_59.geometry}
            material={materials.Caoutchouc}
            position={[0, 0.054, 0]}
          />
        </group>
      </group>
    </group>
  );
};

useGLTF.preload('/scene.gltf');
