/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 iPod.gltf
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { Mesh } from 'three';
import { useFrame } from '@react-three/fiber';

export function IPodModel(props: any) {
  const { nodes, materials } = useGLTF('/models/ipod/iPod.gltf') as any;

  const meshRef = useRef<Mesh>(null!);
  useFrame(() => {
    // Изменяем поворот объекта с течением времени
    meshRef.current.rotation.y -= 0.001;
  });

  return (
    <group
      {...props}
      dispose={null}
      rotation={[0, Math.PI * 1.45, 0]}
      ref={meshRef}
    >
      <group scale={1.5}>
        <mesh
          geometry={nodes.clickwheel_low.geometry}
          material={materials.Body}
          position={[0.007, -0.003, 0.185]}
        />
        <mesh
          geometry={nodes.firewire_metal_low.geometry}
          material={materials.Body}
          position={[0, 0.05, -0.027]}
        />
        <mesh
          geometry={nodes.firewire_plug_low.geometry}
          material={materials.Body}
          position={[0.004, 0.01, -0.03]}
        />
        <mesh
          geometry={nodes.hold_switch_low.geometry}
          material={materials.Body}
          position={[-0.259, 0.031, -0.017]}
        />
        <mesh
          geometry={nodes.inner_plastic_panel_low.geometry}
          material={materials.Body}
          position={[0, 0, -0.024]}
        />
        <mesh
          geometry={nodes.metal_back_low.geometry}
          material={materials.Body}
        />
        <mesh
          geometry={nodes.separation_panel_low.geometry}
          material={materials.Body}
          position={[0, 0, -0.02]}
        />
        <mesh
          geometry={nodes.top_io_low.geometry}
          material={materials.Body}
          position={[0, 0.027, -0.025]}
        />
        <mesh
          geometry={nodes.transparent_panel_low.geometry}
          material={materials.TransparentPlastic}
          position={[0, 0, -0.033]}
        />
      </group>
    </group>
  );
}

useGLTF.preload('/iPod.gltf');
