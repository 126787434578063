/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 scene.gltf
Author: zizian (https://sketchfab.com/zizian)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/3d-model-lamborghini-aventador-svj-9c6d3f2d491146ff8a31eb04edda6876
Title: 3D model Lamborghini Aventador SVJ
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { Mesh } from 'three';
import { useFrame } from '@react-three/fiber';

export function Model1(props: any) {
  const { nodes, materials } = useGLTF(
    '/models/lamborghini1/scene.gltf'
  ) as any;

  const meshRef = useRef<Mesh>(null!);
  useFrame(() => {
    // Изменяем поворот объекта с течением времени
    meshRef.current.rotation.y -= 0.001;
  });

  return (
    <group {...props} dispose={null} position={[0, -5, 0]} ref={meshRef}>
      <group rotation={[3.141, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh
            geometry={nodes.Mesh1_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh2_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh3_Carro_Interno_0.geometry}
            material={materials.Carro_Interno}
          />
          <mesh
            geometry={nodes.Mesh4_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh5_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh6_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh7_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh8_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh9_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh10_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh11_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh12_Carro_Metal_Lanterna_Traseira_0.geometry}
            material={materials.Carro_Metal_Lanterna_Traseira}
          />
          <mesh
            geometry={nodes.Mesh13_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={
              nodes.Mesh14_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh15_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh16_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh17_Carro_Interno_0.geometry}
            material={materials.Carro_Interno}
          />
          <mesh
            geometry={nodes.Mesh18_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh19_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh20_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh21_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh22_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh23_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={
              nodes.Mesh24_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh25_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh26_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh27_Carro_Cromado_0.geometry}
            material={materials.Carro_Cromado}
          />
          <mesh
            geometry={nodes.Mesh28_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh29_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh30_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={
              nodes.Mesh31_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh31_Carro_Pintura_0.geometry}
            material={materials.Carro_Pintura}
          />
          <mesh
            geometry={
              nodes.Mesh32_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh33_Carro_Metal_Vermelho_3_0.geometry}
            material={materials.Carro_Metal_Vermelho_3}
          />
          <mesh
            geometry={nodes.Mesh34_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh35_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh36_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh37_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh38_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh39_Carro_Cromado_0.geometry}
            material={materials.Carro_Cromado}
          />
          <mesh
            geometry={nodes.Mesh40_Carro_Pintura_1_0.geometry}
            material={materials.Carro_Pintura_1}
          />
          <mesh
            geometry={nodes.Mesh41_Carro_Verde_0.geometry}
            material={materials.Carro_Verde}
          />
          <mesh
            geometry={nodes.Mesh42_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh43_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh44_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh45_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh46_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh47_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh47_Carro_Metal_Preto_1_0_1.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh48_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh49_Carro_Metal_Vermelho_4_0.geometry}
            material={materials.Carro_Metal_Vermelho_4}
          />
          <mesh
            geometry={nodes.Mesh50_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh51_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh52_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh53_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh54_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh55_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh56_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh57_Carro_Interno_1_0.geometry}
            material={materials.Carro_Interno_1}
          />
          <mesh
            geometry={nodes.Mesh58_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh59_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh60_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh61_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh62_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh63_Carro_Freio_Disco_0.geometry}
            material={materials.Carro_Freio_Disco}
          />
          <mesh
            geometry={nodes.Mesh64_Carro_Pneu_0.geometry}
            material={materials.Carro_Pneu}
          />
          <mesh
            geometry={nodes.Mesh64_FrontColor_0.geometry}
            material={materials.FrontColor}
          />
          <mesh
            geometry={nodes.Mesh65_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh65_Carro_Plastico_0_1.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh66_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh67_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh68_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh69_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh70_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh71_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh72_Carro_Pintura_0.geometry}
            material={materials.Carro_Pintura}
          />
          <mesh
            geometry={nodes.Mesh73_Carro_Espelhos_0.geometry}
            material={materials.Carro_Espelhos}
          />
          <mesh
            geometry={
              nodes.Mesh74_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh74_Carro_Pintura_0.geometry}
            material={materials.Carro_Pintura}
          />
          <mesh
            geometry={
              nodes.Mesh75_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh76_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh77_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh78_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh79_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh80_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh81_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh82_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh83_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh84_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={
              nodes.Mesh85_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh85_Carro_Pintura_0.geometry}
            material={materials.Carro_Pintura}
          />
          <mesh
            geometry={nodes.Mesh86_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh87_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh88_Carro_Refletor_Farol_1_0.geometry}
            material={materials.Carro_Refletor_Farol_1}
          />
          <mesh
            geometry={nodes.Mesh88_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh88_Carro_Refletor_Farol_0.geometry}
            material={materials.Carro_Refletor_Farol}
          />
          <mesh
            geometry={nodes.Mesh89_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh90_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh91_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh92_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh93_Carro_Vidro_Laranja_0.geometry}
            material={materials.Carro_Vidro_Laranja}
          />
          <mesh
            geometry={nodes.Mesh94_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh95_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh96_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh97_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh98_Carro_Pintura_1_0.geometry}
            material={materials.Carro_Pintura_1}
          />
          <mesh
            geometry={nodes.Mesh99_Carro_Verde_0.geometry}
            material={materials.Carro_Verde}
          />
          <mesh
            geometry={nodes.Mesh100_Carro_Metal_Vermelho_3_0.geometry}
            material={materials.Carro_Metal_Vermelho_3}
          />
          <mesh
            geometry={nodes.Mesh101_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh102_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh103_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh104_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh105_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh106_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh107_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh108_Carro_Vidro_Laranja_0.geometry}
            material={materials.Carro_Vidro_Laranja}
          />
          <mesh
            geometry={nodes.Mesh109_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh110_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh111_Carro_Interno_1_0.geometry}
            material={materials.Carro_Interno_1}
          />
          <mesh
            geometry={nodes.Mesh112_Carro_Interno_2_0.geometry}
            material={materials.Carro_Interno_2}
          />
          <mesh
            geometry={nodes.Mesh113_Carro_Interno_0.geometry}
            material={materials.Carro_Interno}
          />
          <mesh
            geometry={
              nodes.Mesh114_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh115_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh116_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh117_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh118_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh119_Carro_Cromado_0.geometry}
            material={materials.Carro_Cromado}
          />
          <mesh
            geometry={nodes.Mesh119_Carro_Cromado_0_1.geometry}
            material={materials.Carro_Cromado}
          />
          <mesh
            geometry={nodes.Mesh120_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh121_Carro_Metal_Vermelho_1_0.geometry}
            material={materials.Carro_Metal_Vermelho_1}
          />
          <mesh
            geometry={nodes.Mesh121_Carro_Vidros_Vermelhos_0.geometry}
            material={materials.Carro_Vidros_Vermelhos}
          />
          <mesh
            geometry={nodes.Mesh122_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh123_Carro_Metal_Vermelho_1_0.geometry}
            material={materials.Carro_Metal_Vermelho_1}
          />
          <mesh
            geometry={nodes.Mesh123_Carro_Metal_Vermelho_2_0.geometry}
            material={materials.Carro_Metal_Vermelho_2}
          />
          <mesh
            geometry={nodes.Mesh124_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh125_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh126_Carro_Vidros_Vermelhos_0.geometry}
            material={materials.Carro_Vidros_Vermelhos}
          />
          <mesh
            geometry={nodes.Mesh127_Carro_Refletor_Lanterna_0.geometry}
            material={materials.Carro_Refletor_Lanterna}
          />
          <mesh
            geometry={nodes.Mesh128_Carro_Vidros_Vermelhos_1_0.geometry}
            material={materials.Carro_Vidros_Vermelhos_1}
          />
          <mesh
            geometry={nodes.Mesh129_Carro_Metal_Vermelho_0.geometry}
            material={materials.Carro_Metal_Vermelho}
          />
          <mesh
            geometry={nodes.Mesh130_Carro_Metal_Lanterna_Traseira_0.geometry}
            material={materials.Carro_Metal_Lanterna_Traseira}
          />
          <mesh
            geometry={nodes.Mesh131_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh132_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh132_Carro_Plastico_0_1.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh133_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh134_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh135_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh136_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh137_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh138_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh139_Carro_Pintura_0.geometry}
            material={materials.Carro_Pintura}
          />
          <mesh
            geometry={nodes.Mesh140_Carro_Espelhos_0.geometry}
            material={materials.Carro_Espelhos}
          />
          <mesh
            geometry={
              nodes.Mesh141_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh141_Carro_Pintura_0.geometry}
            material={materials.Carro_Pintura}
          />
          <mesh
            geometry={
              nodes.Mesh142_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh143_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh144_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh145_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh146_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh147_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh148_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh149_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh150_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh151_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={
              nodes.Mesh152_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh153_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh154_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh155_Carro_Refletor_Farol_1_0.geometry}
            material={materials.Carro_Refletor_Farol_1}
          />
          <mesh
            geometry={nodes.Mesh155_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh155_Carro_Refletor_Farol_0.geometry}
            material={materials.Carro_Refletor_Farol}
          />
          <mesh
            geometry={nodes.Mesh156_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh157_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh158_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh159_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh160_Carro_Vidro_Laranja_0.geometry}
            material={materials.Carro_Vidro_Laranja}
          />
          <mesh
            geometry={nodes.Mesh161_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh162_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh163_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh164_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh165_Carro_Pintura_1_0.geometry}
            material={materials.Carro_Pintura_1}
          />
          <mesh
            geometry={nodes.Mesh166_Carro_Verde_0.geometry}
            material={materials.Carro_Verde}
          />
          <mesh
            geometry={nodes.Mesh167_Carro_Metal_Vermelho_3_0.geometry}
            material={materials.Carro_Metal_Vermelho_3}
          />
          <mesh
            geometry={nodes.Mesh168_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh169_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh170_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh171_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh172_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh173_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh174_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh175_Carro_Vidro_Laranja_0.geometry}
            material={materials.Carro_Vidro_Laranja}
          />
          <mesh
            geometry={nodes.Mesh176_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh177_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh178_Carro_Interno_1_0.geometry}
            material={materials.Carro_Interno_1}
          />
          <mesh
            geometry={nodes.Mesh179_Carro_Interno_2_0.geometry}
            material={materials.Carro_Interno_2}
          />
          <mesh
            geometry={nodes.Mesh180_Carro_Interno_0.geometry}
            material={materials.Carro_Interno}
          />
          <mesh
            geometry={
              nodes.Mesh181_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh182_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={
              nodes.Mesh183_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
          <mesh
            geometry={nodes.Mesh184_Carro_Plastico_1_0.geometry}
            material={materials.Carro_Plastico_1}
          />
          <mesh
            geometry={nodes.Mesh185_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh186_Carro_Cromado_0.geometry}
            material={materials.Carro_Cromado}
          />
          <mesh
            geometry={nodes.Mesh186_Carro_Cromado_0_1.geometry}
            material={materials.Carro_Cromado}
          />
          <mesh
            geometry={nodes.Mesh187_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh188_Carro_Metal_Vermelho_1_0.geometry}
            material={materials.Carro_Metal_Vermelho_1}
          />
          <mesh
            geometry={nodes.Mesh188_Carro_Vidros_Vermelhos_0.geometry}
            material={materials.Carro_Vidros_Vermelhos}
          />
          <mesh
            geometry={nodes.Mesh189_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh190_Carro_Metal_Vermelho_1_0.geometry}
            material={materials.Carro_Metal_Vermelho_1}
          />
          <mesh
            geometry={nodes.Mesh190_Carro_Metal_Vermelho_2_0.geometry}
            material={materials.Carro_Metal_Vermelho_2}
          />
          <mesh
            geometry={nodes.Mesh191_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh192_Carro_Vidros_0.geometry}
            material={materials.Carro_Vidros}
          />
          <mesh
            geometry={nodes.Mesh193_Carro_Vidros_Vermelhos_0.geometry}
            material={materials.Carro_Vidros_Vermelhos}
          />
          <mesh
            geometry={nodes.Mesh194_Carro_Refletor_Lanterna_0.geometry}
            material={materials.Carro_Refletor_Lanterna}
          />
          <mesh
            geometry={nodes.Mesh195_Carro_Vidros_Vermelhos_1_0.geometry}
            material={materials.Carro_Vidros_Vermelhos_1}
          />
          <mesh
            geometry={nodes.Mesh196_Carro_Metal_Vermelho_0.geometry}
            material={materials.Carro_Metal_Vermelho}
          />
          <mesh
            geometry={nodes.Mesh197_Carro_Metal_Lanterna_Traseira_0.geometry}
            material={materials.Carro_Metal_Lanterna_Traseira}
          />
          <mesh
            geometry={nodes.Mesh198_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh199_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh199_Carro_Metal_Preto_1_0_1.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh200_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh201_Carro_Metal_Vermelho_4_0.geometry}
            material={materials.Carro_Metal_Vermelho_4}
          />
          <mesh
            geometry={nodes.Mesh202_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh203_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh204_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh205_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh206_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh207_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh208_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh209_Carro_Interno_1_0.geometry}
            material={materials.Carro_Interno_1}
          />
          <mesh
            geometry={nodes.Mesh210_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh211_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh212_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh213_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh214_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh215_Carro_Freio_Disco_0.geometry}
            material={materials.Carro_Freio_Disco}
          />
          <mesh
            geometry={nodes.Mesh216_Carro_Pneu_0.geometry}
            material={materials.Carro_Pneu}
          />
          <mesh
            geometry={nodes.Mesh216_FrontColor_0.geometry}
            material={materials.FrontColor}
          />
          <mesh
            geometry={nodes.Mesh217_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh217_Carro_Metal_Preto_1_0_1.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh218_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh219_Carro_Metal_Vermelho_4_0.geometry}
            material={materials.Carro_Metal_Vermelho_4}
          />
          <mesh
            geometry={nodes.Mesh220_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh221_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh222_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh223_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh224_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh225_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh226_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh227_Carro_Interno_1_0.geometry}
            material={materials.Carro_Interno_1}
          />
          <mesh
            geometry={nodes.Mesh228_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh229_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh230_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh231_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh232_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh233_Carro_Freio_Disco_0.geometry}
            material={materials.Carro_Freio_Disco}
          />
          <mesh
            geometry={nodes.Mesh234_Carro_Pneu_0.geometry}
            material={materials.Carro_Pneu}
          />
          <mesh
            geometry={nodes.Mesh234_FrontColor_0.geometry}
            material={materials.FrontColor}
          />
          <mesh
            geometry={nodes.Mesh235_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh235_Carro_Metal_Preto_1_0_1.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh236_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh237_Carro_Metal_Vermelho_4_0.geometry}
            material={materials.Carro_Metal_Vermelho_4}
          />
          <mesh
            geometry={nodes.Mesh238_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh239_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh240_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh241_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh242_Carro_Metal_Preto_1_0.geometry}
            material={materials.Carro_Metal_Preto_1}
          />
          <mesh
            geometry={nodes.Mesh243_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh244_Carro_Metal_Preto_0.geometry}
            material={materials.Carro_Metal_Preto}
          />
          <mesh
            geometry={nodes.Mesh245_Carro_Interno_1_0.geometry}
            material={materials.Carro_Interno_1}
          />
          <mesh
            geometry={nodes.Mesh246_Carro_Cromado_1_0.geometry}
            material={materials.Carro_Cromado_1}
          />
          <mesh
            geometry={nodes.Mesh247_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh248_Carro_Plastico_0.geometry}
            material={materials.Carro_Plastico}
          />
          <mesh
            geometry={nodes.Mesh249_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh250_Carro_Metal_Amarelo_1_0.geometry}
            material={materials.Carro_Metal_Amarelo_1}
          />
          <mesh
            geometry={nodes.Mesh251_Carro_Freio_Disco_0.geometry}
            material={materials.Carro_Freio_Disco}
          />
          <mesh
            geometry={nodes.Mesh252_Carro_Pneu_0.geometry}
            material={materials.Carro_Pneu}
          />
          <mesh
            geometry={nodes.Mesh252_FrontColor_0.geometry}
            material={materials.FrontColor}
          />
          <mesh
            geometry={nodes.Mesh253_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh254_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh255_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh256_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh257_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh258_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh259_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh260_Placa_Parafusos_0.geometry}
            material={materials.Placa_Parafusos}
          />
          <mesh
            geometry={nodes.Mesh261_Placa_Parafusos_0.geometry}
            material={materials.Placa_Parafusos}
          />
          <mesh
            geometry={nodes.Mesh262_Placa_Preto_0.geometry}
            material={materials.Placa_Preto}
          />
          <mesh
            geometry={nodes.Mesh262_Placa_Branco_0.geometry}
            material={materials.Placa_Branco}
          />
          <mesh
            geometry={nodes.Mesh262_Placa_Azul_0.geometry}
            material={materials.Placa_Azul}
          />
          <mesh
            geometry={nodes.Mesh262_Placa_Mercosul_0.geometry}
            material={materials.Placa_Mercosul}
          />
          <mesh
            geometry={nodes.Mesh262_Placa_Bandeira_0.geometry}
            material={materials.Placa_Bandeira}
          />
          <mesh
            geometry={nodes.Mesh262_Placa_QRCode_0.geometry}
            material={materials.Placa_QRCode}
          />
          <mesh
            geometry={
              nodes.Mesh263_HD_wallpaper_blue_circuit_tech_technology_0.geometry
            }
            material={materials.HD_wallpaper_blue_circuit_tech_technology}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/scene.gltf');
