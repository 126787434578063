/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 scene.gltf
Author: Karol Miklas (https://sketchfab.com/karolmiklas)
License: CC-BY-SA-4.0 (http://creativecommons.org/licenses/by-sa/4.0/)
Source: https://sketchfab.com/3d-models/free-1972-datsun-240k-gt-b2303a552b444e5b8637fdf5169b41cb
Title: (FREE) 1972 Datsun 240k GT
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Mesh } from 'three';

export function Model(props: any) {
  const meshRef = useRef<Mesh>(null!);
  const { nodes, materials } = useGLTF('/models/car/scene.gltf') as any;

  useFrame(() => {
    // Изменяем поворот объекта с течением времени
    // meshRef.current.rotation.y -= 0.001;
  });

  return (
    <group {...props} dispose={null} ref={meshRef}>
      <group position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={0.013}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[-392.543, -171.041, -646.595]}
            rotation={[-Math.PI / 2, 0, -Math.PI]}
            scale={[103.06, 115.468, 115.468]}
          >
            <mesh
              geometry={nodes.Cylinder013_alloy_0.geometry}
              material={materials.alloy}
            />
            <mesh
              geometry={nodes.Cylinder013_black_paint_0.geometry}
              material={materials.black_paint}
            />
            <mesh
              geometry={nodes.Cylinder013_tire_0.geometry}
              material={materials.tire}
            />
            <mesh
              geometry={nodes.Cylinder014_tire_0.geometry}
              material={materials.tire}
              rotation={[-0.373, 1.278, 0.512]}
              scale={[0.01, 0.01, 0.014]}
            />
          </group>
          <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
            <group
              position={[3.334, -3.889, 1.265]}
              rotation={[-0.511, -0.11, 0.391]}
            >
              <mesh
                geometry={nodes.wyciroczka001_black_matte_0.geometry}
                material={materials.black_matte}
              />
              <mesh
                geometry={nodes.wyciroczka001_chrome_0.geometry}
                material={materials.chrome}
              />
            </group>
            <group
              position={[3.925, 6.466, -1.71]}
              scale={[1.031, 1.155, 1.155]}
            >
              <mesh
                geometry={nodes.Cylinder012_alloy_0.geometry}
                material={materials.alloy}
              />
              <mesh
                geometry={nodes.Cylinder012_black_paint_0.geometry}
                material={materials.black_paint}
              />
              <mesh
                geometry={nodes.Cylinder012_tire_0.geometry}
                material={materials.tire}
              />
            </group>
            <group
              position={[-0.662, -3.057, 0.906]}
              rotation={[-Math.PI / 2, 0, 0]}
            >
              <mesh
                geometry={nodes.Plane055_black_matte_0.geometry}
                material={materials.black_matte}
              />
              <mesh
                geometry={nodes.Plane055_chrome_0.geometry}
                material={materials.chrome}
              />
              <mesh
                geometry={nodes.Plane055_black_paint_0.geometry}
                material={materials.black_paint}
              />
            </group>
            <group rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes.Plane054_black_paint_0.geometry}
                material={materials.black_paint}
              />
              <mesh
                geometry={nodes.Plane054_chrome_0.geometry}
                material={materials.chrome}
              />
              <mesh
                geometry={nodes.Plane054_chrome_0_1.geometry}
                material={materials.chrome}
              />
              <mesh
                geometry={nodes.Plane054_chrome_0_2.geometry}
                material={materials.chrome}
              />
              <mesh
                geometry={nodes.Plane054_chrome_0_3.geometry}
                material={materials.chrome}
              />
              <mesh
                geometry={nodes.Plane054_black_matte_0.geometry}
                material={materials.black_matte}
              />
              <mesh
                geometry={nodes.Plane054_black_matte_0_1.geometry}
                material={materials.black_matte}
              />
            </group>
            <group position={[0, -0.008, 0]} rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes.Plane053_headlights_0.geometry}
                material={materials.headlights}
              />
              <mesh
                geometry={nodes.Plane053_orange_glass_0.geometry}
                material={materials.orange_glass}
              />
              <mesh
                geometry={nodes.Plane053_chrome_0.geometry}
                material={materials.chrome}
              />
            </group>
            <group rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes.Plane049_black_matte_0.geometry}
                material={materials.black_matte}
              />
              <mesh
                geometry={nodes.Plane049_black_paint_0.geometry}
                material={materials.black_paint}
              />
            </group>
            <group rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes.Plane047_red_glass_0.geometry}
                material={materials.red_glass}
              />
              <mesh
                geometry={nodes.Plane047_chrome_0.geometry}
                material={materials.chrome}
              />
              <mesh
                geometry={nodes.Plane047_headlights_0.geometry}
                material={materials.headlights}
              />
              <mesh
                geometry={nodes.Plane047_black_matte_0.geometry}
                material={materials.black_matte}
              />
            </group>
            <group rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes.Plane046_black_matte_0.geometry}
                material={materials.black_matte}
              />
              <mesh
                geometry={nodes.Plane046_chrome_0.geometry}
                material={materials.chrome}
              />
            </group>
            <group
              position={[3.888, -7.662, -1.71]}
              rotation={[0, 0, -0.239]}
              scale={[1.031, 1.155, 1.155]}
            >
              <mesh
                geometry={nodes.Cylinder011_alloy_0.geometry}
                material={materials.alloy}
              />
              <mesh
                geometry={nodes.Cylinder011_black_paint_0.geometry}
                material={materials.black_paint}
              />
              <mesh
                geometry={nodes.Cylinder011_tire_0.geometry}
                material={materials.tire}
              />
            </group>
            <group position={[0, 0.185, 0]} rotation={[-Math.PI, 0, 0]}>
              <mesh
                geometry={nodes.Plane039_headlights_0.geometry}
                material={materials.headlights}
              />
              <mesh
                geometry={nodes.Plane039_orange_glass_0.geometry}
                material={materials.orange_glass}
              />
              <mesh
                geometry={nodes.Plane039_chrome_0.geometry}
                material={materials.chrome}
              />
            </group>
            <mesh
              geometry={nodes.Plane036_black_matte_0.geometry}
              material={materials.black_matte}
            />
            <mesh
              geometry={nodes.Plane057_chrome_0.geometry}
              material={materials.chrome}
            />
            <mesh
              geometry={nodes.Plane056_license_0.geometry}
              material={materials.license}
              position={[0, 11.434, -0.954]}
              rotation={[-1.377, 0, -Math.PI]}
              scale={0.962}
            />
            <mesh
              geometry={nodes.Plane052_black_matte_0.geometry}
              material={materials.black_matte}
            />
            <mesh
              geometry={nodes.Plane051_glass_0.geometry}
              material={materials.glass}
            />
            <mesh
              geometry={nodes.Cube001_black_paint_0.geometry}
              material={materials.black_paint}
              position={[-1.129, -10.895, -0.163]}
              rotation={[Math.PI / 2, 0, Math.PI]}
            />
            <mesh
              geometry={nodes.Plane050_paint_0.geometry}
              material={materials.paint}
            />
            <mesh
              geometry={nodes.Plane048_black_matte_0.geometry}
              material={materials.black_matte}
              rotation={[-Math.PI, 0, 0]}
            />
            <mesh
              geometry={nodes.Plane045_paint_0.geometry}
              material={materials.paint}
            />
            <mesh
              geometry={nodes.Plane045_paint_0_1.geometry}
              material={materials.paint}
            />
            <mesh
              geometry={nodes.Plane044_black_matte_0.geometry}
              material={materials.black_matte}
            />
            <mesh
              geometry={nodes.Plane043_chrome_0.geometry}
              material={materials.chrome}
            />
            <mesh
              geometry={nodes.Plane042_black_matte_0.geometry}
              material={materials.black_matte}
            />
            <mesh
              geometry={nodes.Plane041_coat_0.geometry}
              material={materials.coat}
            />
            <mesh
              geometry={nodes.Plane041_coat_0_1.geometry}
              material={materials.coat}
            />
            <mesh
              geometry={nodes.Plane041_coat_0_2.geometry}
              material={materials.coat}
            />
            <mesh
              geometry={nodes.Plane040_license_0.geometry}
              material={materials.license}
              position={[1.332, -11.729, -1.245]}
              rotation={[1.757, 0.031, -0.008]}
              scale={0.871}
            />
            <mesh
              geometry={nodes.Plane038_orange_glass_0.geometry}
              material={materials.orange_glass}
              rotation={[-Math.PI, 0, 0]}
            />
            <mesh
              geometry={nodes.Plane037_orange_glass_0.geometry}
              material={materials.orange_glass}
              rotation={[-Math.PI, 0, 0]}
            />
          </group>
          <group
            position={[-389.323, -172.791, 728.157]}
            rotation={[-Math.PI / 2, -0.026, 2.83]}
            scale={[103.06, 115.468, 115.468]}
          >
            <mesh
              geometry={nodes.Cylinder007_alloy_0.geometry}
              material={materials.alloy}
            />
            <mesh
              geometry={nodes.Cylinder007_black_paint_0.geometry}
              material={materials.black_paint}
            />
            <mesh
              geometry={nodes.Cylinder007_tire_0.geometry}
              material={materials.tire}
            />
            <mesh
              geometry={nodes.Cylinder008_tire_0.geometry}
              material={materials.tire}
              rotation={[-0.373, 1.278, 0.512]}
              scale={[0.01, 0.01, 0.014]}
            />
          </group>
          <group
            position={[-438.679, 70.135, -1050.148]}
            rotation={[-1.626, -1.394, -1.625]}
            scale={126.708}
          >
            <mesh
              geometry={nodes.Plane034_stickers_0.geometry}
              material={materials.stickers}
            />
            <mesh
              geometry={nodes.Plane034_chrome_0.geometry}
              material={materials.chrome}
            />
          </group>
          <group
            position={[440.04, 70.219, -972.605]}
            rotation={[-1.707, 1.332, 1.705]}
            scale={126.708}
          >
            <mesh
              geometry={nodes.Plane031_stickers_0.geometry}
              material={materials.stickers}
            />
            <mesh
              geometry={nodes.Plane031_chrome_0.geometry}
              material={materials.chrome}
            />
          </group>
          <mesh
            geometry={nodes.Sphere001_headlights_0.geometry}
            material={materials.headlights}
            rotation={[Math.PI, 0, 0]}
            scale={[56.676, 56.676, 38.196]}
          />
          <mesh
            geometry={nodes.Plane035__0.geometry}
            material={materials['Plane.035__0']}
            position={[0, -341.508, 0]}
            rotation={[-Math.PI / 2, 0, 0]}
            scale={[685.922, 1371.843, 685.922]}
          />
          <mesh
            geometry={nodes.Cylinder010_tire_0.geometry}
            material={materials.tire}
            position={[392.543, -171.041, -646.595]}
            rotation={[-1.942, 1.278, 0.499]}
            scale={[1.195, 1.195, 1.404]}
          />
          <mesh
            geometry={nodes.Cylinder009_tire_0.geometry}
            material={materials.tire}
            position={[388.814, -171.041, 766.162]}
            rotation={[-1.137, 1.27, -0.339]}
            scale={[1.195, 1.195, 1.404]}
          />
          <mesh
            geometry={nodes.Plane033_stickers_0.geometry}
            material={materials.stickers}
            position={[-129.723, 37.745, -1157.984]}
            rotation={[-3.093, -0.022, -3.14]}
            scale={75.045}
          />
          <mesh
            geometry={nodes.Plane032_stickers_0.geometry}
            material={materials.stickers}
            position={[369.478, -126.175, -1112.152]}
            rotation={[2.772, 0.117, -1.23]}
            scale={129.135}
          />
          <mesh
            geometry={nodes.Plane029_stickers_0.geometry}
            material={materials.stickers}
            position={[157.01, -15.816, 1116.22]}
            scale={73.118}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/scene.gltf');
