import * as THREE from 'three';
import * as React from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';

import { Environment } from '@react-three/drei';
import { Model1 } from './Model1';
import { Waiting } from '../Waiting';

const Light = () => {
  return (
    <mesh>
      {/* <directionalLight color={0xffffff} intensity={1} position={[20, 0, 20]} /> */}
      {/* <directionalLight
        color={0xffffff}
        intensity={1}
        position={[20, 0, -20]}
      /> */}
      {/* <directionalLight
        color={0xffffff}
        intensity={1}
        position={[-20, 0, -20]}
      /> */}
      {/* <directionalLight
        color={0xffffff}
        intensity={1}
        position={[-20, 0, 20]}
      /> */}
      <directionalLight color={0xffffff} intensity={1} position={[0, 20, 0]} />
      <directionalLight color={0xffffff} intensity={1} position={[0, -20, 0]} />
    </mesh>
  );
};

const SceneCamera = () => {
  return <OrbitControls enablePan={false} maxPolarAngle={Math.PI / 2} />;
};

const Ground = () => {
  // const colorMap = useLoader(THREE.TextureLoader, '/bg/breakstone-1.jpg');
  const texture = useTexture('/bg/breakstone-1.jpg');
  texture.repeat.set(150, 150);
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

  const logo = useLoader(THREE.TextureLoader, '/bg/logo.png');
  // logo.repeat.set(10, 10);
  // logo.offset = new THREE.Vector2(-0.1, -0.2);

  return (
    <>
      {/* Гравий */}
      <mesh position={[0, -6, 0]}>
        <boxGeometry args={[1000, 0.1, 1000]} />
        <meshStandardMaterial map={texture} displacementScale={1} />
      </mesh>

      {/* Подиум */}
      <mesh position={[0, -5.9, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        {/* <boxGeometry args={[50, 2, 50]} /> */}
        <circleGeometry args={[30, 100]} />
        <meshStandardMaterial
          color={0xdddddd}
          // shadowSide={THREE.DoubleSide}
          // flatShading={true}
        />
      </mesh>

      {/* Нижняя часть баннера */}
      <mesh position={[-32, -2.5, 0]} rotation={[0, Math.PI / 2, 0]}>
        <boxGeometry args={[20, 10, 0.5]} />
        <meshBasicMaterial color={0x3a3a3a} />
      </mesh>

      {/* Верхняя часть баннера */}
      <mesh position={[-32, 7.5, 0]} rotation={[0, Math.PI / 2, 0]}>
        <boxGeometry args={[20, 10, 0.5]} />
        <meshStandardMaterial map={logo} />
      </mesh>

      <mesh position={[50, -5, 0]} rotation={[0, Math.PI / 2, 0]}>
        <boxGeometry args={[100, 50, 0.1]} />
        <meshBasicMaterial color={0xaaaaaa} />
      </mesh>

      <mesh position={[-50, -5, 0]} rotation={[0, Math.PI / 2, 0]}>
        <boxGeometry args={[100, 50, 0.1]} />
        <meshBasicMaterial color={0xaaaaaa} />
      </mesh>

      <mesh position={[0, -5, 50]} rotation={[0, 0, 0]}>
        <boxGeometry args={[100, 50, 0.1]} />
        <meshBasicMaterial color={0x333333} />
      </mesh>

      <mesh position={[0, -5, -50]} rotation={[0, 0, 0]}>
        <boxGeometry args={[100, 50, 0.1]} />
        <meshBasicMaterial color={0x333333} />
      </mesh>
    </>
  );
};

export const Lamborghini1Scene = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas camera={{ position: [15, 2, 15], zoom: 1 }}>
        <React.Suspense fallback={<Waiting />}>
          {/* <Model /> */}
          <Model1 />
          <color attach="background" args={[new THREE.Color(0x111111)]} />
          <Light />
          <SceneCamera />
          <Ground />
          <Environment preset="warehouse" />
        </React.Suspense>
      </Canvas>
    </div>
  );
};
