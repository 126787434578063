import { Html, useProgress } from '@react-three/drei';
import React from 'react';

export const Waiting = () => {
  const { progress } = useProgress();
  return (
    <Html center>
      {Math.round(progress)}%. Черный ящик открывается, а в нем ...
    </Html>
  );
};
