import * as THREE from 'three';
import * as React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Model } from './Model';
import { Waiting } from '../Waiting';

const Light = () => {
  return (
    <mesh>
      <directionalLight
        color={0xffffff}
        intensity={0.1}
        position={[20, 0, 20]}
      />
      <directionalLight
        color={0xffffff}
        intensity={1}
        position={[20, 0, -20]}
      />
      <directionalLight
        color={0x010101}
        intensity={1}
        position={[-20, 0, -20]}
      />
      <directionalLight
        color={0xffffff}
        intensity={1}
        position={[-20, 0, 20]}
      />
      <directionalLight color={0xffffff} intensity={1} position={[0, 20, 0]} />
      <directionalLight color={0xffffff} intensity={1} position={[0, -20, 0]} />
    </mesh>
  );
};

const SceneCamera = () => {
  return <OrbitControls enablePan={false} maxPolarAngle={Math.PI / 2} />;
};

export const BinocularScene = () => {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <Canvas camera={{ position: [16, 10, 16], zoom: 1 }}>
        <React.Suspense fallback={<Waiting />}>
          <color attach="background" args={[new THREE.Color(0xeeeeee)]} />
          <Light />
          <SceneCamera />
          <Model />
        </React.Suspense>
      </Canvas>
    </div>
  );
};
