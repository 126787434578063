import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { CarScene } from './pages/Car/Scene';
import { JoystickScene } from './pages/Joystick/Scene';

import './index.css';
import { Home } from './pages/Home';
import { IPodScene } from './pages/Ipod/Scene';
import { Lamborghini1Scene } from './pages/Lamborghini1/Scene';
import { Lamborghini2Scene } from './pages/Lamborghini2/Scene';
import { BinocularScene } from './pages/Binocular/Scene';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/car" element={<CarScene />} />
        <Route path="/binocular" element={<BinocularScene />} />
        <Route path="/lamborghini1" element={<Lamborghini1Scene />} />
        <Route path="/lamborghini2" element={<Lamborghini2Scene />} />
        <Route path="/ipod" element={<IPodScene />} />
        <Route path="/joystick" element={<JoystickScene />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
