/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.7 scene.gltf
Author: SDC PERFORMANCE™️ (https://sketchfab.com/3Duae)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/lamborghini-aventador-svj-sdc-free-784e4656aca649cca55d6b18740a19b2
Title: Lamborghini Aventador SVJ SDC ( FREE )
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Mesh } from 'three';

export function Model(props: any) {
  const { nodes, materials } = useGLTF(
    '/models/lamborghini2/scene.gltf'
  ) as any;

  const meshRef = useRef<Mesh>(null!);
  useFrame(() => {
    // Изменяем поворот объекта с течением времени
    meshRef.current.rotation.y -= 0.001;
  });

  return (
    <group
      {...props}
      dispose={null}
      ref={meshRef}
      scale={10}
      position={[0, -5.9, 0]}
    >
      <group scale={0.001}>
        <mesh
          geometry={nodes.Object_4.geometry}
          material={materials.CARROSSERIE}
        />
        <mesh geometry={nodes.Object_5.geometry} material={materials.CARBONE} />
        <mesh geometry={nodes.Object_6.geometry} material={materials.CARBONE} />
        <mesh
          geometry={nodes.Object_7.geometry}
          material={materials.MetalDark}
        />
        <mesh geometry={nodes.Object_8.geometry} material={materials.Chrome} />
        <mesh
          geometry={nodes.Object_9.geometry}
          material={materials.ExteriorDetailTex}
        />
        <mesh
          geometry={nodes.Object_10.geometry}
          material={materials.MetalExhaustTextured}
        />
        <mesh
          geometry={nodes.Object_11.geometry}
          material={materials.GlassLights}
        />
        <mesh
          geometry={nodes.Object_12.geometry}
          material={materials.Undercarriage}
        />
        <mesh
          geometry={nodes.Object_13.geometry}
          material={materials.intPlasticDkPlain}
        />
        <mesh
          geometry={nodes.Object_14.geometry}
          material={materials.MetalGoldenLogo}
        />
        <mesh
          geometry={nodes.Object_15.geometry}
          material={materials.intAlcantara}
        />
        <mesh
          geometry={nodes.Object_16.geometry}
          material={materials.intTexButtonsGlossy}
        />
        <mesh
          geometry={nodes.Object_17.geometry}
          material={materials.Aluminum}
        />
        <mesh
          geometry={nodes.Object_18.geometry}
          material={materials['Material.001']}
        />
        <mesh
          geometry={nodes.Object_19.geometry}
          material={materials['Material.002']}
        />
        <mesh
          geometry={nodes.Object_20.geometry}
          material={materials['Material.003']}
        />
        <mesh geometry={nodes.Object_21.geometry} material={materials.GLASS} />
      </group>
      <group scale={0.001}>
        <mesh
          geometry={nodes.Object_23.geometry}
          material={materials.CARROSSERIE}
        />
        <mesh
          geometry={nodes.Object_24.geometry}
          material={materials.CARBONE}
        />
        <mesh geometry={nodes.Object_25.geometry} material={materials.Chrome} />
        <mesh geometry={nodes.Object_26.geometry} material={materials.GLASS} />
      </group>
      <group position={[0, 1.098, -0.616]} scale={0.001}>
        <mesh
          geometry={nodes.Object_28.geometry}
          material={materials.CARROSSERIE}
        />
        <mesh
          geometry={nodes.Object_29.geometry}
          material={materials.MetalDark}
        />
        <mesh geometry={nodes.Object_30.geometry} material={materials.GLASS} />
        <mesh
          geometry={nodes.Object_31.geometry}
          material={materials.Undercarriage}
        />
        <mesh
          geometry={nodes.Object_32.geometry}
          material={materials.CARBONE}
        />
      </group>
      <group position={[0, 0.103, -0.006]} scale={0.001}>
        <mesh
          geometry={nodes.Object_34.geometry}
          material={materials.CARBONE}
        />
        <mesh geometry={nodes.Object_35.geometry} material={materials.Chrome} />
        <mesh
          geometry={nodes.Object_36.geometry}
          material={materials.HdLightsLens}
        />
        <mesh
          geometry={nodes.Object_37.geometry}
          material={materials.MetalExhaustTextured}
        />
        <mesh
          geometry={nodes.Object_38.geometry}
          material={materials.GlassLights}
        />
        <mesh
          geometry={nodes.Object_39.geometry}
          material={materials.LightEmissiveWhite}
        />
      </group>
      <group position={[0, 0.755, 1.324]} scale={0.001}>
        <mesh
          geometry={nodes.Object_41.geometry}
          material={materials.CARROSSERIE}
        />
        <mesh
          geometry={nodes.Object_42.geometry}
          material={materials.CARBONE}
        />
      </group>
      <group
        position={[0.862, 0.323, 1.221]}
        rotation={[0, -0.28, 0]}
        scale={0.001}
      >
        <mesh
          geometry={nodes.Object_46.geometry}
          material={materials.WheelMetalBlack}
        />
        <mesh
          geometry={nodes.Object_47.geometry}
          material={materials.WheelTireBump}
        />
        <mesh
          geometry={nodes.Object_48.geometry}
          material={materials['Material.006']}
        />
        <mesh
          geometry={nodes.Object_49.geometry}
          material={materials['Material.005']}
        />
      </group>
      <group scale={0.001}>
        <mesh
          geometry={nodes.Object_55.geometry}
          material={materials.WheelMetalBlack}
        />
        <mesh
          geometry={nodes.Object_56.geometry}
          material={materials.WheelTireBump}
        />
        <mesh
          geometry={nodes.Object_57.geometry}
          material={materials['Material.006']}
        />
        <mesh
          geometry={nodes.Object_58.geometry}
          material={materials['Material.005']}
        />
        <mesh
          geometry={nodes.Object_59.geometry}
          material={materials.material_0}
        />
      </group>
      <group position={[0.002, 0.098, -0.012]} scale={0.001}>
        <mesh geometry={nodes.Object_63.geometry} material={materials.GLS_MR} />
        <mesh
          geometry={nodes.Object_64.geometry}
          material={materials.CARBONE}
        />
        <mesh
          geometry={nodes.Object_65.geometry}
          material={materials.MetalDark}
        />
        <mesh geometry={nodes.Object_66.geometry} material={materials.Chrome} />
        <mesh
          geometry={nodes.Object_67.geometry}
          material={materials.GlassLights}
        />
        <mesh
          geometry={nodes.Object_68.geometry}
          material={materials.GlassRedLights}
        />
        <mesh
          geometry={nodes.Object_69.geometry}
          material={materials.GlassOrangeLights}
        />
        <mesh
          geometry={nodes.Object_70.geometry}
          material={materials.GlassWhite}
        />
        <mesh
          geometry={nodes.Object_71.geometry}
          material={materials.Aluminum}
        />
      </group>
      <group
        position={[-0.87, 0.323, 1.221]}
        rotation={[-Math.PI, 0.294, -Math.PI]}
        scale={0.001}
      >
        <mesh
          geometry={nodes.Object_73.geometry}
          material={materials.WheelMetalBlack}
        />
        <mesh
          geometry={nodes.Object_74.geometry}
          material={materials.WheelTireBump}
        />
        <mesh
          geometry={nodes.Object_75.geometry}
          material={materials['Material.006']}
        />
        <mesh
          geometry={nodes.Object_76.geometry}
          material={materials['Material.005']}
        />
      </group>
      <mesh
        geometry={nodes.Object_44.geometry}
        material={materials.Undercarriage}
        position={[0, 0.103, -0.007]}
        scale={0.001}
      />
      <mesh
        geometry={nodes.Object_51.geometry}
        material={materials['Material.004']}
        position={[0.871, 0.29, 1.079]}
        scale={0.001}
      />
      <mesh
        geometry={nodes.Object_53.geometry}
        material={materials.MetalRedEngine}
        position={[-0.865, 0.29, 1.079]}
        scale={0.001}
      />
      <mesh
        geometry={nodes.Object_61.geometry}
        material={materials['Material.004']}
        position={[0.872, 0.418, -1.42]}
        scale={0.001}
      />
      <mesh
        geometry={nodes.Object_78.geometry}
        material={materials.CARBONE}
        scale={0.001}
      />
    </group>
  );
}

useGLTF.preload('/scene.gltf');
